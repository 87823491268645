/* General */
.theme-bkg{
  background: #0F0F0F;
}
.theme-txt{
  color: #82512D;
}

.txt-lgray{
  color: #A7A7A7;
}
.txt-gray{
  color: #787878;
}
.txt-lbrown{
  color: #ffffffa6;
}
.txt-nero{
  color: #1E1E1E;
}
.txt-white{
  color: #F0F0F0;
}

.pinewood-texture{
  /* background-image: url(../public/images/general/white-pinewood2.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #2E3133;
}

.box-out{
  /* box-shadow: 3px 3px 5px 0px rgb(0 0 0 / 19%), -3px -3px 4px 0px #FFF; */
  background: #424445;
  border-radius:15px;
}

.box-in{
  /* box-shadow: 3px 3px 4px 0px #FFF, 3px 3px 4px 0px rgba(0, 0, 0, 0.08) inset;   */
  background:#1E1E1E;
  border-radius:15px;
}

.box-selected {
  border-radius: 15px;
  background: linear-gradient(135deg, #82512D 7.04%, #CE8F3C 86.82%);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset;
}

.box-default{
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF;
  background: #eee;
  border-radius:15px;
}

.box-auth{
  width:450px;
  max-width:95%;
  margin:0 auto;
  padding:0 30px
}

.divider-white {
  background: #F1F1F1;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px #FFF;
  height: 4px;
  margin: 5px 0;
}

/* Auth */
.login-box{
  padding:40px 0px 50px 0px;
  text-align: center;
  background: #eee;
  border-radius: 20px;
  width: 100%;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.80);
}

.pwd-box{
  padding-left: 20vw !important;
}

.login-container{
  position: relative;
  width: 877px;
  /* min-width: 444px; */
  margin: 0 auto;
  padding-left: 120px;
  max-width: 80%;
}

.login-product{
  left:-10vh;
  padding-top:70px;
  z-index:2;
  width: 450px;
  max-width:40vw;
  position: absolute;
}

.forgot-img{
  left:-10vh;
  z-index:2;
  width: 500px;
  max-width:40vw;
  position: absolute;
  filter: drop-shadow(5px 5px 12px #0005);
}

.maintenance-desc{
  text-align: left;
  color: #82512D;
}

/* Button */

.btn-disable, .btn-white, .btn-main{
  border-radius: 35px 0px !important;
  padding:10px 30px!important;
  min-width: 100px!important;
}
.btn-disable {
  
  background: #E8E8E8;
  /* inside shadow */
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.10) inset, -3px -3px 4px 0px #FFF inset;
}

.btn-white {
  background: #EEE;
  /* Outside Shadow */
  color: #222;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF;
}

.btn-main {
  background: var(--dark-red, #B51111) !important;
  color: #eee;
  /* Gradient Main */
  box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, 2px 2px 3px 0px rgba(0, 0, 0, 0.10)!important;
}
.btn-ver-red, .btn-ver-white{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.btn-ver-red {
  background: linear-gradient(109.07deg, #A51616 3.41%, #C33838 98.09%); 
  box-shadow:4px 4px 8px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset
}
.btn-ver-white {
  background: #EEE;
  box-shadow: 2px 2px 3px 0px #FFF inset, -2px -2px 2px 0px rgba(176, 120, 37, 0.29) inset;
}

.btn-icon-round{
  width: 50px;
  height: 50px;
  background-color: #eee;
  color: #82512D;
  border-radius: 48px;
  box-shadow: 2px 2px 3px 0px #FFF inset, -2px -2px 2px 0px rgba(176, 120, 37, 0.29) inset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-info{
  background-color: #CB2020;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  color: #F0F0F0;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.15), -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset;
}

/* Dashboard */
.register-title{
  color:#fff;
  padding:15px 20px;
  border-radius:15px;
  background-color: #2E3133;
  box-shadow: 0px 4px 5px 0px #D1913C52, 2px 2px 4px 0px #FFFFFF85 inset, -2px -2px 4px 0px #00000021 inset;
  width: 350px;
  min-width: 200px;
  margin: -60px auto 30px;
  max-width: 80%;
}

.dashboard-container{
  padding: 12vh 50px;
}

.dashboard-w{
  border-radius:15px;
  margin: 0 auto; 
  /* box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.10), -3px -3px 4px 0px #FFF; */
  max-width: 100%;
  padding: 60px 55px 80px;
  /* background-image: url(../public/images/profile/mountain_bg.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top 47px center;
  width: 550px;
  position: relative;
  min-height: 400px;
  background-color: #eee;
}

@media screen and (max-width: 600px){
  .dashboard-w{
    padding: 60px 35px 80px;
  }
  .dashboard-container{
    padding: 12vh 30px;
  }
}

.frame-size {
  width: 90px;
}

.title-bg {
  border-radius: 15px;
  padding: 10px 20px;
  background: linear-gradient(90deg, #CB2020 0%, #CB2020 78.5%, #B51111 100%);
  box-shadow: 0px 4px 5px 0px #D13C3C2B;
}
.btn-animate:hover .menu-animate{
  transform: translateY(0);
  transition: all 0.5s ease-out;
  backdrop-filter: blur(0);
  animation-name: blurbkg;
  animation-duration: 2s;
  animation-delay: 0.05s;
  animation-fill-mode: forwards;
}
.menu-animate{
  transform: translateY(300px);
  transition: all 0.7s ease-in;
  top:0;
  left:0;
  background: rgba(0, 0, 0, 0.4)
}

@keyframes blurbkg {
  0%   {backdrop-filter: blur(0px)}
  100% {backdrop-filter: blur(2px)}
}

/* circular path */
.circular-fill {
  stroke-dasharray: 283;
  stroke-dashoffset: 283;
  animation: fillAnimation 1s linear forwards;
}

@keyframes fillAnimation {
  from {
    stroke-dashoffset: 283;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.sel-bkg{
  background: linear-gradient(90deg, #CB2020 0%, #CB2020 78.5%, #B51111 100%);
  box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #222;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.default-bkg{
  background: transparent;
  color: #fff;
}
@media screen and (max-width: 900px){
  .login-box{
    padding:100px 40px 70px 40px;
    border-radius: 20px;
    width: 100%;
    box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.80);
    margin-top: -95px;
  }
  .pwd-box{
    padding-left: 40px !important;
  }
  .login-container{
    position: relative;
    padding-left: 0;
    padding-top:0;
    flex-direction: column;
    padding-bottom: 50px;
  }
  .login-product, .forgot-img{
    left:unset;
    padding-top:0;
    top: -100px;
    z-index:2;
    min-width:200px;
    position: unset;
  }
  .maintenance-container{
    flex-direction: column;
    margin: 50px;
  }
  .maintenance-desc{
    text-align: center;
    padding-top: 15px;
    width: unset;
  }
  .flex-col-mobile{
    flex-direction: column;
  }
  .register-title{
    margin-bottom: 0;
    color:#82512D;
    padding:40px 20px 15px;
    background: transparent;
    box-shadow: none;
  }
  /* .dashboard-w{
    box-shadow: none;
  } */
  .frame-size {
    width: 75px;
  }
}

/* react date picker */
.DateRangePickerInput{
  height: 45px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.DateRangePickerInput__withBorder{
  background-color: transparent !important;
  border: none !important;
  flex-wrap: wrap !important;
}

.DateRangePickerInput_arrow{
  color: white !important;
}

.DateInput{
  background: transparent !important;
}

.DateInput_input{
  background: transparent !important;
  color: white !important;
}

.DateInput_input::placeholder{
  color: white !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before{
  border-right:33px solid #CB2020 !important;
}

.DateInput_input__focused{
  border-bottom: 2px solid #CB2020 !important;
}

/* date range picker calender */
.CalendarDay__selected{
  background-color: #CB2020 !important;
  border:1px double #CB2020 !important;
}

.CalendarDay__selected_span{
  background-color: #CB202040 !important;
  border:1px double #CB202040 !important;
}

.CalendarDay__selected:hover {
  background: #CB202030 !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #CB202050 !important;
  border:1px double #CB202040 !important;
  color:#CB2020 !important ;
}